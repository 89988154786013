import React, { useRef, useEffect, useState } from 'react';
import { ListItemButton,Avatar, Container, Typography, Grid2, Paper, Toolbar, CssBaseline, Box, Button, TextField, ListItem, ListItemIcon, ListItemText, Drawer, List, Collapse } from '@mui/material';
import SensorsIcon from '@mui/icons-material/Sensors';
import { FixedSizeList } from "react-window";
import HistoryChart from '../components/HistoryChart';
import { useNavigate, useLocation } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


const Row = ({ data, className, style , onClick, lastUpdated }) => { // 添加 lastUpdated 参数
    const timeSinceUpdate = Math.floor((Date.now() - lastUpdated) / 1000); // 计算更新时间
    return (
      <div style={style}>
        <ListItem button onClick={() => onClick()}>
          <Paper elevation={2} style={{ borderRadius: '8px', padding: '8px', width: '100%' }}>
            <Grid2 container alignItems="center">
              <Grid2 item xs={2}>
                <ListItemIcon>
                  <Avatar variant="rounded" style={{backgroundColor:'#009999'}}>
                    <SensorsIcon />
                  </Avatar>
                </ListItemIcon>
              </Grid2>
              <Grid2 item>
                <ListItemText
                  primary={
                    <Typography 
                      variant="h6" 
                      style={{ color: (className === 'selected' ? 'lightblue' : (timeSinceUpdate > 605 ? 'grey':'inherit') )}} // 修改颜色
                    >
                      {data[1].name}
                    </Typography>
                  }
                />
              </Grid2>
            </Grid2>
          </Paper>
        </ListItem>
      </div>
    );
};
  
function SensorCard({ sensorId, data, lastUpdated, handleChartClickOpen }) {
const [timeSinceUpdate, setTimeSinceUpdate] = useState(0);


function gethumdity_ratio({temp_celsius,rh}){
    // constant
    const n1 = 0.11670521452767e+04
    const n2 = -0.72421316703206e+06
    const n3 = -0.17073846940092e+02
    const n4 = 0.12020824702470e+05
    const n5 = -0.32325550322333e+07
    const n6 = 0.14915108613530e+02
    const n7 = -0.48232657361591e+04
    const n8 = 0.40511340542057e+06
    const n9 = -0.23855557567849e+00
    const n10 = 0.65017534844798e+03
    const epsilon = 0.621945
    const air_pressure = 1009.01  // hPa, obtained from local weather station

    //Calculate θ
    const theta = (temp_celsius + 273.15) + n9 / ((temp_celsius + 273.15) - n10)
    
    // Calculate A, B, and C
    const A = theta ** 2 + n1 * theta + n2
    const B = n3 * theta ** 2 + n4 * theta + n5
    const C = n6 * theta ** 2 + n7 * theta + n8

    // Calculate the value of the expression inside the brackets
    const inner_expression = 2 * C / (-B + Math.sqrt(B ** 2 - 4 * A * C))

    // Calculate p_ws
    const p_ws = 10000 * inner_expression ** 4

    // Calculate saturation vapor pressure (Ps) in hPa
    const Ps = 6.11 * 10 ** ((7.5 * temp_celsius) / (237.3 + temp_celsius))

    // Calculate actual vapor pressure (E) in hPa
    const Pw = (rh / 100) * p_ws * 1

    // Calculate humidity ratio W in g/kg
    const W = epsilon * (Pw / (air_pressure - Pw)) * 1000

    return W
}

const humdity_ratio_scd = data.scd?gethumdity_ratio({temp_celsius:data.scd["4"]/100,rh:data.scd["5"]/100}):null

const humdity_ratio_sfa = data.sfa?gethumdity_ratio({temp_celsius:data.sfa["4"]/100,rh:data.sfa["5"]/100}):null

useEffect(() => {
    const interval = setInterval(() => {
    setTimeSinceUpdate(Math.floor((Date.now() - lastUpdated) / 1000));
    }, 1000);

    return () => clearInterval(interval);
}, [lastUpdated]);

return (
    <Grid2 item xs={12} sm={12} md={6}>
    <Paper elevation={3} style={{ padding: '16px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
        {(data?.name) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Name:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.name}</Typography>
        </div>
        :null}

        {(sensorId) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>MAC:</Typography>
            <Typography fontSize={14} variant="body1" noWrap pb={1}>{sensorId.toUpperCase()}</Typography>
        </div>
        :null}

        {(data?.scd?.w) ?
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Uptime [Hrs]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.scd.w}</Typography>
          </div>
        :null}

        {(data?.sps?.w) ?
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>WIFI Runtime [ms]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap >{data.sps.w}</Typography>
          </div>
        :null}

        {(data?.scd?.v) ?
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Battery [V]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.scd.v / 1000).toFixed(3)}</Typography>
          </div>
        :null}

        {(data?.scd?.["3"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>CO2 [ppm]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.scd["3"]}</Typography>
        </div>
        :null}
        {(data?.scd?.["4"]) ?
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Temperature-SCD [°C]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.scd["4"] / 100).toFixed(2)}</Typography>
          </div>
        :null}
        {(data?.scd?.["5"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Humidity-SCD [%]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.scd["5"] / 100).toFixed(2)}</Typography>
        </div>
        :null}

        {(humdity_ratio_scd) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Humidity Ratio-SCD [g/kg]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap pb={1}>{humdity_ratio_scd.toFixed(2)}</Typography>
        </div>
        :null}

        {(data?.bmp?.["4"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Temperature-BMP [°C]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.bmp["4"] / 100).toFixed(2)}</Typography>
        </div>
        :null}

        {(data?.bmp?.["5"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Mean Pressure-BMP [Pa]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.bmp["5"] / 100).toFixed(2)}</Typography>
        </div>
        :null}

        {(data?.bmp?.["6"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Max Pressure-BMP [Pa]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.bmp["6"] / 100).toFixed(2)}</Typography>
        </div>
        :null}

        {(data?.bmp?.["7"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Min Pressure-BMP [Pa]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap pb={1}>{(data.bmp["7"] / 100).toFixed(2)}</Typography>
        </div>
        :null}

        {(data?.sfa?.["3"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>HCHO [ppb-1]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.sfa["3"]}</Typography>
        </div>
        :null}
        {(data?.sfa?.["4"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Temperature-SFA [°C]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.sfa["4"] / 100).toFixed(2)}</Typography>
        </div>
        :null}

        {(data?.sfa?.["5"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Humidity-SFA [%]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.sfa["5"] / 100).toFixed(2)}</Typography>
        </div>
        :null}

        {(humdity_ratio_sfa) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Humidity Ratio-SFA [g/kg]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap pb={1}>{humdity_ratio_sfa.toFixed(2)}</Typography>
        </div>
        :null}
        {(data?.sps?.["0"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>PM 1.0 [µg/m³]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.sps["0"] / 100).toFixed(2)}</Typography>
        </div>
        :null}
        {(data?.sps?.["1"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>PM 2.5 [µg/m³]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.sps["1"] / 100).toFixed(2)}</Typography>
        </div>
        :null}
        {(data?.sps?.["2"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>PM 4.0 [µg/m³]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.sps["2"] / 100).toFixed(2)}</Typography>
        </div>
        :null}
        {(data?.sps?.["3"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>PM 10.0 [µg/m³]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.sps["3"] / 100).toFixed(2)}</Typography>
        </div>
        :null}

        {(data?.sps?.["4"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>NC 0.5 [#/cm³]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.sps["4"] / 100).toFixed(2)}</Typography>
        </div>
        :null}

        {(data?.sps?.["5"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>NC 1.0 [#/cm³]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.sps["5"] / 100).toFixed(2)}</Typography>
        </div>
        :null}

        {(data?.sps?.["6"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>NC 2.5 [#/cm³]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.sps["6"] / 100).toFixed(2)}</Typography>
        </div>
        :null}

        {(data?.sps?.["7"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>NC 4.0 [#/cm³]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.sps["7"] / 100).toFixed(2)}</Typography>
        </div>
        :null}

        {(data?.sps?.["8"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>NC 10.0 [#/cm³]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.sps["8"] / 100).toFixed(2)}</Typography>
        </div>
        :null}

        {(data?.sps?.["9"]) ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Typical particle size [µm]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{(data.sps["9"] / 100).toFixed(2)}</Typography>
        </div>
        :null}
        
        </div>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} pt={1} borderTop="1px solid #e0e0e0">
        <Typography fontSize={14} variant="body1" noWrap style={{ color: timeSinceUpdate > 605 ? 'red' : 'inherit', visibility: timeSinceUpdate === 0 ? 'hidden' : 'visible' }}>
            Updated: {timeSinceUpdate} seconds ago
        </Typography>
        <Button variant="contained" onClick={handleChartClickOpen}>
            History
        </Button>
        </Box>
    </Paper>
    </Grid2>
);
}

const Sensors = ({userData, notification}) => {

  const location = useLocation(); // 使用 useLocation 钩子
  const state = location.state;

  const [sensorData, setSensorData] = useState({});
  const [lastUpdated, setLastUpdated] = useState({});
  const [selectedSensorId, setSelectedSensorId] = useState(''); // 新增状态以跟踪选中的传感器ID
  const [displayedRecords, setDisplayedRecords] = useState(null);
  const [buttonState, setButtonState] = useState('online');
  const [MenuOpen, setMenuOpen] = useState(false); // 新增状态以控制 Drawer
  const [drawerOpen, setDrawerOpen] = useState(false); // 新增状态以控制 Drawer
  const containerRef = useRef(null);
  const [openChart, setopenChart] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [open, setOpen] = useState(true);

  console.log(sensorData)

  useEffect(() => {
    if (state?.notification){
        setSearchTerm(state?.notification ? state.notification : '');
        setButtonState(state?.notification ? 'offline' : 'offline');
        setDisplayedRecords(state?.notification ? Object.entries(sensorData).map(([sensorId]) => sensorId) : Object.entries(sensorData).map(([sensorId]) => sensorId));
    }
  }, [state?.notification]);
  
  const handleChartClickOpen = () => {
    setopenChart(true);
  };

  const handleChartClickClose = () => {
    setopenChart(false);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  
  const fetchLastRecord = async () => {
    try {
      const response = await fetch('/api/sensor/lastrecord');
      const data = await response.json();
      if (data.error){
        window.location.href = '/';
        return;
      }
      Object.entries(data).forEach(([sensorId, sensorData]) => {
        const currentTime = new Date(sensorData.timestamp).getTime();

        const SCDData = sensorData.SCD ? JSON.parse(sensorData.SCD) : null;
        const SFAData = sensorData.SFA ? JSON.parse(sensorData.SFA) : null;
        const SPSData = sensorData.SPS ? JSON.parse(sensorData.SPS) : null;
        const BMPData = sensorData.BMP ? JSON.parse(sensorData.BMP) : null;

        if(sensorData.name != sensorData.deviceId){
          setSensorData(prevData => ({
            ...prevData,
            [sensorId]: { name: sensorData.name, sps: SPSData,scd: SCDData, sfa: SFAData, bmp: BMPData, ...sensorData } // 更新 SPS 数据
          }));
          setLastUpdated(prevData => ({
            ...prevData,
            [sensorId]: currentTime
          }));
        }else{
          console.log(sensorId + " Unloaded");
        }
      });
    } catch (error) {
      console.error('Error fetching last record:', error);
    }
  };

  useEffect(() => {
    fetchLastRecord();
    const interval = setInterval(fetchLastRecord, 5000); // 每30秒调用一次 fetchLastRecord
    return () => clearInterval(interval); // 清除定时器
  }, []);

 const filteredRecords = displayedRecords ? 
   (Object.entries(sensorData)
     .filter(([sensorId, data]) => data.name.includes(searchTerm))
     .sort((a, b) => a[1].name.localeCompare(b[1].name))) // 按名称排序
   :
   (Object.entries(sensorData)
     .filter(([sensorId]) => 
       Object.entries(lastUpdated)
         .filter(([_, time]) => Math.floor(Date.now() - time) < 605 * 1000)
         .map(([sensorId]) => sensorId)
         .includes(sensorId))
     .filter(([sensorId, data]) => data.name.includes(searchTerm))
     .sort((a, b) => a[1].name.localeCompare(b[1].name))); // 按名称排序
  return (
    <>
    {openChart && <HistoryChart sensorName={sensorData[selectedSensorId].name} sensorId={selectedSensorId} open={openChart} onClose={handleChartClickClose}/>}
      <CssBaseline />
      <Container ref={containerRef}>
        <Box paddingLeft={window.innerWidth > 500 ? 20 : 0} paddingRight={window.innerWidth > 500 ? 20 : 0}>
          <div style={{marginTop:'10px'}}/>
          <Paper elevation={3}>
          <List style={{marginTop:'0px'}}>
            <ListItemButton disabled={open} onClick={() =>setOpen(!open)}>
              <ListItemText primary="Search List" />
              {(selectedSensorId) && (open ? null : <ExpandMore />)}
            </ListItemButton>
            <Collapse in={open}>
              <ListItem>
              <TextField
                  style={{marginLeft:'10px',marginRight:'10px',marginTop:'10px'}}
                  variant="outlined"
                  placeholder="Search by Name"
                  value={searchTerm}
                  onChange={(e) => (
                      setButtonState(null),
                      setSearchTerm(e.target.value)              
                  )}
                  fullWidth // 修改为 fullWidth
                  InputProps={{
                    endAdornment: (
                      searchTerm && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setSearchTerm('')}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                      )
                    ),
                  }}
                />
              </ListItem>
            <ListItem>
            <FixedSizeList
              height={375} // 设置列表高度
              itemSize={75} // 每个项的高度
              itemCount={filteredRecords.length} // 项的数量
              width="100%" // 列表宽度
            >
              {({ index, style }) => {
                const sensorId = filteredRecords[index][0]; // 获取传感器ID
                return (
                  <Row
                    style={style} 
                    onClick={() => {
                      setSelectedSensorId(sensorId);
                      handleDrawerOpen(); // 点击时打开 Drawer
                      setOpen(false)
                    }} 
                    className={selectedSensorId === sensorId ? 'selected' : ''} // 添加选中样式
                    data = {filteredRecords[index]}
                    lastUpdated={lastUpdated[sensorId]} // 传递 lastUpdated 参数
                  />
                );
              }}
            </FixedSizeList>
            </ListItem>

            <ListItem>
            <Button
              fullWidth
              variant="contained" 
              onClick={() => {
                if (buttonState === 'offline'){
                  setButtonState(null);
                  setDisplayedRecords(null);
                  return;
                }else{
                  setButtonState('offline'); // 设置选中的状态
                  setDisplayedRecords(Object.entries(sensorData).map(([sensorId]) => sensorId));
                  return;
                }
              }} // 仅显示在线传感器
              size="large"
            >
              {buttonState === 'offline' ? 'ONLINE ONLY' : 'SHOW ALL'}
            </Button>
          </ListItem>
          </Collapse>
          </List>
          </Paper>

          <div style={{marginTop:'10px'}}/>

          {(selectedSensorId && !open) && (
            <SensorCard 
              key={selectedSensorId} 
              sensorId={selectedSensorId} 
              data={sensorData[selectedSensorId]} 
              lastUpdated={lastUpdated[selectedSensorId]} 
              handleChartClickOpen={handleChartClickOpen}
            />
          )}
        </Box>
      <Box>
      </Box>
      </Container>
    </>
  );
};

export default Sensors;