import React, { useEffect, useState, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Stack,Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Select, MenuItem, ButtonGroup } from '@mui/material';
import { makeStyles } from '@mui/styles'; 
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'chart.js/auto';

const dataThingslist = [
  ["scd",1,1],
  ["scd",2,100],
  ["scd",3,100],
  ["sps",0,100],
  ["sps",1,100],
  ["sps",2,100],
  ["sps",3,100],
  ["sps",5,100],
]


const datalist = [
  ["scd",0,1000],
  ["scd",1,1],
  ["scd",2,100],
  ["scd",3,100],
  ["sfa",0,1],
  ["sfa",1,100],
  ["sfa",2,100],
  ["sps",0,100],
  ["sps",1,100],
  ["sps",2,100],
  ["sps",3,100],
  ["sps",4,100],
  ["sps",5,100],
  ["sps",6,100],
  ["sps",7,100],
  ["sps",8,100],
  ["sps",9,100],
]

const dataBMPlist = [
  ["scd",0,1000],
  ["scd",1,1],
  ["scd",2,100],
  ["scd",3,100],
  ["bmp",0,100],
  ["bmp",1,100],
  ["bmp",2,100],
  ["bmp",3,100],
  ["sps",0,100],
  ["sps",1,100],
  ["sps",2,100],
  ["sps",3,100],
  ["sps",4,100],
  ["sps",5,100],
  ["sps",6,100],
  ["sps",7,100],
  ["sps",8,100],
  ["sps",9,100],
]

const dataThingsLabels = [
  "Co2 [ppm]",
  "Temperature-SCD [°C]",
  "Humidity-SCD [%]",
  "PM 1.0 [µg/m³]",
  "PM 2.5 [µg/m³]",
  "PM 4.0 [µg/m³]",
  "PM 10.0 [µg/m³]",
  "NC 1.0 [#/cm³]",
];

const dataLabels = [
  "Battery [V]",
  "Co2 [ppm]",
  "Temperature-SCD [°C]",
  "Humidity-SCD [%]",
  "HCHO [ppb]",
  "Temperature-SFA [°C]",
  "Humidity-SFA [%]",
  "PM 1.0 [µg/m³]",
  "PM 2.5 [µg/m³]",
  "PM 4.0 [µg/m³]",
  "PM 10.0 [µg/m³]",
  "NC 0.5 [#/cm³]",
  "NC 1.0 [#/cm³]",
  "NC 2.5 [#/cm³]",
  "NC 4.0 [#/cm³]",
  "NC 10.0 [#/cm³]",
  "Typical particle size [µm]"
];

const dataBMPLabels = [
  "Battery [V]",
  "Co2 [ppm]",
  "Temperature-SCD [°C]",
  "Humidity-SCD [%]",
  "Temperature-BMP [°C]",
  "Mean Pressure-BMP [Pa]",
  "Max Pressure-BMP [Pa]",
  "Min Pressure-BMP [Pa]",
  "PM 1.0 [µg/m³]",
  "PM 2.5 [µg/m³]",
  "PM 4.0 [µg/m³]",
  "PM 10.0 [µg/m³]",
  "NC 0.5 [#/cm³]",
  "NC 1.0 [#/cm³]",
  "NC 2.5 [#/cm³]",
  "NC 4.0 [#/cm³]",
  "NC 10.0 [#/cm³]",
  "Typical particle size [µm]"
];

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100%',
    maxWidth: '800px', // Default max width
    height: '200%',
    marginTop: '0px',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function HistoryChart({ sensorName,sensorId, open, onClose }) {
  const classes = useStyles(); // Use the styles
  const [chartData, setChartData] = useState(null);
  const [wssData, setWssData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataPeriod, setDataPeriod] = useState(1); // Default to 1H

  const type = (sensorId.length == 7) ? 'things' : wssData.bmp ? 'bmp' : 'data';

  const [selectedDate, setSelectedDate] = useState(new Date());

  const updateChartData = (data, index) => {
    const timestamps = (type === 'things') ? Object.keys(data[dataThingslist[index][0]]) : (type === 'bmp') ? Object.keys(data[dataBMPlist[index][0]]) : Object.keys(data[datalist[index][0]]);
    const hour1_interval = Math.ceil(timestamps.length / (window.innerWidth > 500 ? 120 : 120 ))
    const hour3_interval = Math.ceil(timestamps.length / (window.innerWidth > 500 ? 360 : 360 / 2 ))
    const hour24_interval = Math.ceil(timestamps.length / (window.innerWidth > 500 ? 1440 : 1440 / 2 ))
    const interval = (dataPeriod === 1) ? hour1_interval : (dataPeriod === 3) ? hour3_interval : hour24_interval;    

    const filteredTimestamps = timestamps.filter((_, i) => i % interval === 0);
    const values = filteredTimestamps.map((timestamp,_index)=> {
      const originalIndex = timestamps.findIndex(ts => ts === timestamp);

      if(originalIndex === 0){
        return (type === 'things') ? data[dataThingslist[index][0]][timestamp][dataThingslist[index][1]] / (dataThingslist[index][2]) : (type === 'bmp') ? data[dataBMPlist[index][0]][timestamp][dataBMPlist[index][1]] / (dataBMPlist[index][2]) : data[datalist[index][0]][timestamp][datalist[index][1]] / (datalist[index][2])
      }
      
      const previousIndex = timestamps.findIndex(ts => ts === filteredTimestamps[_index - 1])
      const _templist = timestamps.slice(previousIndex,originalIndex)
      const _values = (type === 'things') ? _templist.map(ts => data[dataThingslist[index][0]][ts][dataThingslist[index][1]] / (dataThingslist[index][2])) : (type === 'bmp') ? _templist.map(ts => data[dataBMPlist[index][0]][ts][dataBMPlist[index][1]] / (dataBMPlist[index][2])) : _templist.map(ts => data[datalist[index][0]][ts][datalist[index][1]] / (datalist[index][2]))
      const _value = (_values.reduce((a, b) => a + b, 0) / _templist.length).toFixed(2)
      return _value
    })

    setChartData({
      labels: filteredTimestamps.map(ts => (dataPeriod === 1) ? new Date(ts).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) : (dataPeriod === 3) ? new Date(ts).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) : (dataPeriod === 24) ? new Date(ts).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) : new Date(ts).toLocaleTimeString([], { day: '2-digit', hour: '2-digit', hour12: false })),
      datasets: [
        {
          label: (type === 'things') ? dataThingsLabels[index] : (type === 'bmp') ? dataBMPLabels[index] : dataLabels[index],
          data: values,
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
        },
      ],
    });
  };

  const handleSelectChange = (event) => {
    const index = event.target.value;
    
    setSelectedIndex(index);
    if (Object.keys(wssData).length > 0) {
      updateChartData(wssData, index);
    } else {
      setChartData(null);
    }
  };

  const handlePeriodChange = (period) => {
    setDataPeriod(period);
  };

  const handleDateChange = (date) => {

    const newDate = new Date(date)

    if(newDate < new Date().setHours(0,0,0,0)){
      setDataPeriod(24)
      newDate.setHours(23,59,59,999)
      setSelectedDate(newDate);
    }else{
      setSelectedDate(newDate);
    }
  };

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const currentTime = selectedDate.getTime();
        const starttTime = selectedDate.getTime() - (dataPeriod * 3600 * 1000);
        const url = "/api/sensor/history?repeator_id=" + sensorId + "&start_time=" + starttTime + "&end_time=" + currentTime;
        const response = await fetch(url);
        const data = await response.json();
        setWssData(data);
        updateChartData(data,selectedIndex)
      } catch (error) {
        console.error('Error fetching last record:', error);
      }
    };
    fetchHistory();
  }, [dataPeriod,sensorId,selectedIndex,selectedDate]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" style={classes} alignItems="center">
      <DialogTitle style={{ textAlign: 'center' ,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
        <Typography fontSize={14} variant="h6">
        {sensorName} History Data on {selectedDate.toLocaleDateString()}
        </Typography>
        <Typography fontSize={14} variant="h6">
        ID : {sensorId.toUpperCase()}
        </Typography>
      </DialogTitle>
      
      <Select value={selectedIndex} onChange={handleSelectChange} style={{ marginLeft: '10px' ,marginRight:'10px',marginBottom:'10px'}}>
        {(type === 'things') ? dataThingsLabels.map((label, i) => (
          <MenuItem key={i} value={i}>
            {label}
          </MenuItem>
        )) : (type === 'bmp') ? dataBMPLabels.map((label, i) => (
          <MenuItem key={i} value={i}>
            {label}
          </MenuItem>
        )) : dataLabels.map((label, i) => (
          <MenuItem key={i} value={i}>
            {label}
          </MenuItem>
        ))}
      </Select>      

      <DialogContent style={{ overflowX: 'auto' ,padding:'5px'}}>
        {chartData ? (
          <Line
            data={chartData}
            height={(window.innerWidth > 500 ? 150 : 300)}
            options={{
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  enabled: true, // 启用工具提示
                  position: 'nearest',
                  intersect: false,
                  mode: 'index',
                }
              },
              scales: {
                y: {
                  ticks: {
                    maxTicksLimit: 10 // Reduce the number of y-axis labels
                  }
                },
                x: {
                  ticks: {
                    maxTicksLimit: 14 // Reduce the number of y-axis labels
                  }
                }
              },
              elements: {
                point: {
                  radius: 0
                },
                line: {
                  borderWidth: (window.innerWidth > 500 ? 2 : 1), // 设置线条宽度为2
                  tension: 0.3 // 设置线条的弯曲度，值在0到1之间，0为直线
                }
              },
            }}
          />
        ) : (
          <Typography>Loading</Typography>
        )}

      </DialogContent>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="center"> 
        <Button 
        onClick={() => handlePeriodChange(1)} 
          variant="contained"
          style={{ 
            backgroundColor: dataPeriod === 1 ? '#999c91' : '#363732', 
            color: '#fff', 
            border: 'none' // Remove border
          }} 
        >
          1H
        </Button>
        <Button 
          onClick={() => handlePeriodChange(3)} 
          variant="contained"
          style={{ 
            backgroundColor: dataPeriod === 3 ? '#999c91' : '#363732', 
            color: '#fff', 
            border: 'none' // Remove border
          }} 
        >
          3H
        </Button>
        <Button 
          onClick={() => handlePeriodChange(24)} 
          variant="contained"
          style={{ 
            backgroundColor: dataPeriod === 24 ? '#999c91' : '#363732', 
            color: '#fff', 
            border: 'none' // Remove border
          }} 
        >
          24H
        </Button>
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" paddingTop={2}>  
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker fullWidth label="Select Date" InputLabelProps={{ shrink: true }} onChange={(e) => {handleDateChange(new Date(e))}}/>
        </LocalizationProvider>
      </Stack>

      <DialogActions>
      </DialogActions>
    </Dialog>
  );
}

export default HistoryChart;