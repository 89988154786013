import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Stack, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Select, MenuItem, ButtonGroup } from '@mui/material';
import { makeStyles } from '@mui/styles'; 
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'chart.js/auto';

const datalist = [
  ["particles",0],
  ["gas",0],
  ["particles",1],
  ["particles",2],
  ["particles",3],
  ["particles",4],
  ["particles",5],
  ["particles",6],
  ["particles",7],
  ["particles",8],
  ["particles",9],
  ["gas",1],
  ["gas",2],
]

const dataLabels = [
  "Battery [V]",
  "CO2 [ppm]",
  "Temperature [°C]",
  "Humidity [%]",
  "Pressure [mBar]",
  "PM 1.0 [µg/m³]",
  "PM 2.5 [µg/m³]",
  "PM 4.0 [µg/m³]",
  "PM 10.0 [µg/m³]",
  "Total Particle Counter [1/m³]",
  "PM Total [µg/m³]",
  "Wind Speed [m/s]",
  "Wind Direction [degrees]",
];

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100%',
    maxWidth: '600px', // Default max width
    height: '200%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%', // Full width on small screens
      margin: '0', // Remove default margin
      height: '200%',
    },
  },
  dialogContent: {
    padding: '10px',
  },
}));

function HistoryChart2({ sensorName,sensorId, open, onClose }) {
  const classes = useStyles(); // Use the styles
  const [chartData, setChartData] = useState(null);
  const [wssData, setWssData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataPeriod, setDataPeriod] = useState(2);

  const [selectedDate, setSelectedDate] = useState(new Date());


  const updateChartData = (data, index) => {
    const timestamps = Object.keys(data[datalist[index][0]]);
    const hour1_interval = Math.ceil(timestamps.length / (window.innerWidth > 500 ? 120 : 120))
    const hour3_interval = Math.ceil(timestamps.length / (window.innerWidth > 500 ? 360 : 360 /2 ))
    const hour24_interval = Math.ceil(timestamps.length / (window.innerWidth > 500 ? 1440 : 1440 /2 ))
    const interval = (dataPeriod === 1) ? hour1_interval : (dataPeriod === 3) ? hour3_interval : hour24_interval;

    const filteredTimestamps = timestamps.filter((_, i) => i % interval === 0);
    //take average of between the interval
    const values = filteredTimestamps.map((timestamp,_index)=> {
    //find the index of the timestamp in the original timestamps
    const originalIndex = timestamps.findIndex(ts => ts === timestamp);
    
    if(originalIndex === 0){
      return data[datalist[index][0]][timestamp][datalist[index][1]]
    }
    const previousIndex = timestamps.findIndex(ts => ts === filteredTimestamps[_index - 1])
    const _templist = timestamps.slice(previousIndex,originalIndex)
    const _values = _templist.map(ts => data[datalist[index][0]][ts][datalist[index][1]])
    const _value = (_values.reduce((a, b) => a + b, 0) / _templist.length).toFixed(2)
    return _value
    })

    setChartData({
            labels: filteredTimestamps.map(ts => (dataPeriod === 1) ? new Date(ts).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) : new Date(ts).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })),
      datasets: [
        {
          label: dataLabels[index],
          data: values,
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
        },
      ],
    });
  };

  const handleSelectChange = (event) => {
    const index = event.target.value;
    
    setSelectedIndex(index);
    if (Object.keys(wssData).length > 0) {
      updateChartData(wssData, index);
    } else {
      setChartData(null);
    }
  };

  const handlePeriodChange = (period) => {
    setDataPeriod(period);
  };

  const handleDateChange = (date) => {

    const newDate = new Date(date)

    if(newDate < new Date().setHours(0,0,0,0)){
      setDataPeriod(24)
      newDate.setHours(23,59,59,999)
      setSelectedDate(newDate);
    }else{
      setSelectedDate(newDate);
    }
  };

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const currentTime = selectedDate.getTime();
        const starttTime = selectedDate.getTime() - (dataPeriod * 3600 * 1000);
        const url = "/api/aqmesh/history?pod_serial_number=" + sensorId + "&start_time=" + starttTime + "&end_time=" + currentTime;
        const response = await fetch(url);
        const data = await response.json();
        console.log(data)
        setWssData(data);
        updateChartData(data,selectedIndex)
      } catch (error) {
        console.error('Error fetching last record:', error);
      }
    };
    fetchHistory();
  }, [dataPeriod,sensorId,selectedIndex,selectedDate]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" style={classes}>
      <DialogTitle style={{ textAlign: 'center' }}>
        <Typography fontSize={14} variant="h6">
        {sensorName} History Data on {selectedDate.toLocaleDateString()}
        </Typography>
        <Typography fontSize={14} variant="h6">
        ID : {sensorId.toUpperCase()}
        </Typography>
      </DialogTitle>

      <Select value={selectedIndex} onChange={handleSelectChange} style={{ marginLeft: '10px' ,marginRight:'10px',marginBottom:'10px'}}>
        {dataLabels.map((label, i) => (
          <MenuItem key={i} value={i}>
            {label}
          </MenuItem>
        ))}
      </Select>

      <DialogContent style={{ overflowX: 'auto' }}>
        {chartData ? (
            <Line
              data={chartData}
              height={(window.innerWidth > 500 ? 150:300)}
              options={{
                //disable labels
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    enabled: true,
                    position: 'nearest',
                    intersect: false,
                    mode: 'index',
                  }
                },
                scales: {
                  y: {
                    ticks: {
                      maxTicksLimit: 10 // Reduce the number of y-axis labels
                    }
                  }
                },
                elements: {
                  point: {
                    radius: 0
                  },
                  line: {
                    borderWidth: (window.innerWidth > 500 ? 2 : 1), // 设置线条宽度为2
                    tension: 0.3 // 设置线条的弯曲度，值在0到1之间，0为直线
                  }
                },
              }}
            />
        ) : (
          <Typography>Loading...</Typography>
        )}

    <Stack direction="row" spacing={2} alignItems="center" justifyContent="center"> 
        <Button 
        onClick={() => handlePeriodChange(2)} 
          variant="contained"
          style={{ 
            backgroundColor: dataPeriod === 2 ? '#999c91' : '#363732', 
            color: '#fff', 
            border: 'none' // Remove border
          }} 
        >
          2H
        </Button>
        <Button 
          onClick={() => handlePeriodChange(6)} 
          variant="contained"
          style={{ 
            backgroundColor: dataPeriod === 6 ? '#999c91' : '#363732', 
            color: '#fff', 
            border: 'none' // Remove border
          }} 
        >
          6H
        </Button>
        <Button 
          onClick={() => handlePeriodChange(24)} 
          variant="contained"
          style={{ 
            backgroundColor: dataPeriod === 24 ? '#999c91' : '#363732', 
            color: '#fff', 
            border: 'none' // Remove border
          }} 
        >
          24H
        </Button>
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" pt={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                <DatePicker fullWidth label="Select Date" InputLabelProps={{ shrink: true }} onChange={(e) => {handleDateChange(new Date(e))}}/>
            </LocalizationProvider>
      </Stack>

      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  );
}

export default HistoryChart2;