import React, { useRef, useEffect, useState } from 'react';
import { OutlinedInput,Stack,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Container, Typography, Grid2, Paper, Toolbar, CssBaseline, Box, Button, TextField, ListItem, ListItemIcon, ListItemText, Drawer, List, Collapse, ListItemButton } from '@mui/material';
import { FixedSizeList } from "react-window";
import HistoryChart2 from '../components/HistoryChart2';
import { useNavigate, useLocation } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import DoneIcon from '@mui/icons-material/Done';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { ExpandLess, ExpandMore } from '@mui/icons-material';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Bar } from 'react-chartjs-2';

const Row = ({ data, className, style , onClick, lastUpdated }) => { // 添加 lastUpdated 参数
    const timeSinceUpdate = Math.floor((Date.now() - lastUpdated) / 1000); // 计算更新时间
    return (
      <div style={style}>
        <ListItem button onClick={() => onClick()}>
          <Paper elevation={2} style={{ borderRadius: '8px', padding: '8px', width: '100%' }}>
            <Grid2 container alignItems="center">
              <Grid2 item xs={2}>
                <ListItemIcon>
                  <Avatar variant="rounded" style={{ backgroundColor: '#009999' }}>
                    <PersonPinCircleIcon />
                  </Avatar>
                </ListItemIcon>
              </Grid2>
              <Grid2 item xs={10}>
                <ListItemText
                  primary={
                    <Typography 
                      variant="h6" 
                      style={{ color: (className === 'selected' ? 'lightblue' : (timeSinceUpdate > 125 ? 'inherit':'inherit') )}} // 修改颜色
                    >
                      {data[1].name}
                    </Typography>
                  }
                />
              </Grid2>
            </Grid2>
          </Paper>
        </ListItem>
      </div>
    );
};
  
function SensorCard({ sensorId, data, lastUpdated, handleChartClickOpen ,peopleHistory, selectedDate, setSelectedDate, offset, setOffset, handleEditOffset, displayMode, setDisplayMode}) { 
const [newOffset, setNewOffset] = useState(data.offset);
// create a list of timestamps from 00:00:00 to 23:59:59 by 1 minute and fit peopleHistory data
const firstTimestamp = peopleHistory && Object.keys(peopleHistory).length > 0 ? Object.keys(peopleHistory)[0] : null;

const fisrtOffset = peopleHistory && Object.values(peopleHistory).length > 0 ? Object.values(peopleHistory)[0].people_count - Object.values(peopleHistory)[0].In + Object.values(peopleHistory)[0].Out : 9999;

//calculate the minutes between the first timestamp and the current timestamp if the first timestamp is today
const minutesBetween = peopleHistory && Object.values(peopleHistory).length > 0 && new Date(firstTimestamp).getDate() === new Date().getDate() ? 
    Math.floor((Date.now() - new Date(firstTimestamp).setHours(0, 0, 0, 0)) / 60000) : 1440;

console.log(minutesBetween)

const timestamps = firstTimestamp ? Array.from({ length: minutesBetween }, (_, i) => {
    const date = new Date(firstTimestamp);
    date.setHours(Math.floor(i / 60), i % 60, 0, 0);
    date.setMinutes(date.getMinutes() + 8*60);
    return date.toISOString().split(".")[0];
}) : [];

const _peopleHistory = fisrtOffset !== 9999 ? Object.entries(timestamps).map(([index, timestamp]) => {
    return (parseInt(index) === 0) ?
        { people_count: fisrtOffset, timestamp: timestamp } :
        (peopleHistory && peopleHistory[timestamp]) ? { people_count: peopleHistory[timestamp].people_count + newOffset , 'timestamp':timestamp, ...peopleHistory[timestamp]} : {'timestamp':timestamp};
}) : [];

let lastValidCount = null;
_peopleHistory.forEach((item, index) => {
    if (!item.people_count) {
        _peopleHistory[index] = { people_count: lastValidCount,...item }; // 用最后有效的计数填充 null 值
    }else{
        lastValidCount = item.people_count;
    }
});

let __peopleHistory = {};

_peopleHistory.forEach((item, index) => {
  if(new Date(item.timestamp).getMinutes() % 5 !== 0){
    return;
  }
  __peopleHistory[item.timestamp] = item;
});



console.log(__peopleHistory);

const exportMonth = async () => {
  // 将时间格式修正为 ISO 字符串
  const starttTime  = selectedDate ? new Date(selectedDate).setDate(1): new Date(new Date().setDate(1)).setHours(0,0,0,0);
  const lastDay = selectedDate ? new Date(starttTime).setMonth(new Date(selectedDate).getMonth() + 1, 1) : new Date(starttTime).setMonth(new Date().getMonth() + 1, 1);

  const url = "/api/people/history?deviceId=" + sensorId + "&start_time=" + starttTime + "&end_time=" + lastDay;
  const response = await fetch(url);
  const _data = await response.json();

  console.log(data);


  const csvRows = [];

  const headers = ['SensorId','TimeStamp','Date','In','Out','Count'];

  csvRows.push(headers.join(','));

  console.log(_data.people);

  if(_data.people){
    Object.entries(_data.people).forEach(([timestamp, item]) => {
      csvRows.push([sensorId,timestamp, item.date, item.In, item.Out, item.people_count + newOffset]);
    });
  }

  const csvString = csvRows.join('\n');
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const _url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', _url);

  const exportstartdate = new Date(starttTime + 8*60*60*1000).toISOString().split('T')[0].replace(/-/g, '');
  const exportenddate = new Date(lastDay + 8*60*60*1000 - 1).toISOString().split('T')[0].replace(/-/g, '');

  link.setAttribute('download', data.name + "_" + exportstartdate + "_" + exportenddate + ".csv"); 
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

//console.log(item.timestamp);

return (
    <Grid2 item xs={12} sm={12} md={6}>
    <Paper elevation={3} style={{ padding: '16px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
        {data?.name&&
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography fontSize={14} variant="body1" noWrap>Name:</Typography>
          <Typography fontSize={14} variant="body1" noWrap>{data.name}</Typography>
        </div>
        }
        {data?.deviceId&&
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Serial:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.deviceId}</Typography>
        </div>
        }

        {peopleHistory&&
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Date:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{selectedDate ? new Date(selectedDate).toLocaleString('en-US', { timeZone: 'Asia/Hong_Kong' }).split(',')[0] : new Date(Object.keys(peopleHistory)[Object.keys(peopleHistory).length - 1]).toLocaleString('en-US', { timeZone: 'Asia/Hong_Kong' }).split(',')[0]}</Typography>
        </div>
        }

        {peopleHistory&&
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Total In:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{Object.keys(peopleHistory).length>0?Object.values(peopleHistory).reduce((acc, curr) => acc + curr.In, 0):'No Data'}</Typography>
            <Typography fontSize={14} variant="body1" noWrap>Total Out:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{Object.keys(peopleHistory).length>0?Object.values(peopleHistory).reduce((acc, curr) => acc + curr.Out, 0):'No Data'}</Typography>
        </div>
        }

        {peopleHistory&&
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Start Time:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{Object.keys(peopleHistory).length>0?new Date(Object.keys(peopleHistory)[0]).toLocaleString('en-US', { timeZone: 'Asia/Hong_Kong' ,hour12:false}):'No Data'}</Typography>
        </div>
        }
        {peopleHistory&&
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>End Time:</Typography>
            <Typography fontSize={14} variant="body1" noWrap pb={2}>{Object.keys(peopleHistory).length>0?new Date(Object.keys(peopleHistory)[Object.keys(peopleHistory).length-1]).toLocaleString('en-US', { timeZone: 'Asia/Hong_Kong' ,hour12:false}):'No Data'}</Typography>
        </div>
        }

        <Button fullWidth variant="contained" color="inherit" onClick={() => setDisplayMode(displayMode==='bar'?'table':'bar')}>{displayMode==='table'?'TABLE VIEW':'BAR VIEW'}</Button>  
        <div style={{marginTop:'10px'}}/>

        {peopleHistory && displayMode==='table'&&(
          <TableContainer sx={{ maxHeight: 300 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontSize={14}>Time</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={14}>In</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={14}>Out</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={14}>Count</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(peopleHistory).map(([timestamp, item]) => (
                  <TableRow key={timestamp}>
                    <TableCell >
                      {timestamp.split('T')[1].split(':')[0] + ":" + timestamp.split('T')[1].split(':')[1]}
                    </TableCell>
                    <TableCell > {item.In} </TableCell>
                    <TableCell > {item.Out} </TableCell>
                    <TableCell > {item.people_count + newOffset} </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {peopleHistory && displayMode==='bar'&&(
        <Bar
          data={{
            labels: Object.keys(__peopleHistory).map((index,timestamp) => index),
            datasets: [
              {
                label: 'People Count',
                data: Object.values(__peopleHistory).map(item => item.people_count + newOffset),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
              },
            ],
          }}
          options={{
            scales: {
              x: {
                  ticks: {
                      maxTicksLimit: minutesBetween == 1440 ? 24 : minutesBetween / 60 + 1,
                      callback: function(index) {
                          return new Date(Object.keys(__peopleHistory)[index]).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' , hour12: false});
                      }
                  }
              }
            },
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
                display: false,
              },
              title: {
                display: false,
                text: 'PEOPLE COUNT',
              },
              tooltip: {
                enabled: true,
                position: 'nearest',
                intersect: false,
                mode: 'index',
              }
            },
          }}
          height={window.innerWidth > 500 ? 125 : 250}
        />
      )}


        <Stack direction="row" spacing={2} pt={2} pb={2} borderTop="1px solid #e0e0e0" justifyContent="center">
          <OutlinedInput 
              value={newOffset}
              inputProps={{ style: { textAlign: 'center' } }} 
              disabled 
              endAdornment={
                newOffset >= data.offset ? (
                <InputAdornment position="end">
                  <IconButton onClick={() => setNewOffset(newOffset + 1)}>
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
                ) : <InputAdornment position="end">
                  <IconButton onClick={() => setNewOffset(data.offset)}>
                    <RestartAltIcon />
                  </IconButton>
                  <IconButton onClick={() => handleEditOffset(sensorId, newOffset)}> 
                    <DoneIcon/>
                  </IconButton>
                </InputAdornment>
              } 
              startAdornment={
                newOffset <= data.offset ? (
                <InputAdornment position="start">
                  <IconButton onClick={() => setNewOffset(newOffset - 1)}>
                    <RemoveIcon />
                  </IconButton>
                </InputAdornment>
                ) : <InputAdornment position="start">
                  <IconButton onClick={() => handleEditOffset(sensorId, newOffset)}> 
                    <DoneIcon/>
                  </IconButton>
                  <IconButton onClick={() => setNewOffset(data.offset)}>
                    <RestartAltIcon />
                  </IconButton>
                </InputAdornment>
              }
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker size='large' onChange={(e) => setSelectedDate(e)}/>
            </LocalizationProvider>

            <Button variant="contained" color="inherit" onClick={exportMonth}>EXPORT</Button>  
        </Stack>
        
        </div>
    </Paper>
    </Grid2>
);
}

const People = ({userData, notification}) => {
  const location = useLocation(); // 使用 useLocation 钩子
  const state = location.state;

  const [peopleData, setPeopleData] = useState({});
  const [lastUpdated, setLastUpdated] = useState({});
  const [selectedSensorId, setSelectedSensorId] = useState(''); // 新增状态以跟踪选中的传感器ID
  const [displayedRecords, setDisplayedRecords] = useState(null);
  const [buttonState, setButtonState] = useState('online');
  const [MenuOpen, setMenuOpen] = useState(false); // 新增状态以控制 Drawer
  const [drawerOpen, setDrawerOpen] = useState(false); // 新增状态以控制 Drawer
  const containerRef = useRef(null);
  const [openChart, setopenChart] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [selectedDate, setSelectedDate] = useState(null);

  const [peopleHistory, setPeopleHistory] = useState(null);

  const [displayMode, setDisplayMode] = useState('bar');

  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (state?.notification){
        setSearchTerm(state?.notification ? state.notification : '');
        setButtonState(state?.notification ? 'offline' : 'offline');
        setDisplayedRecords(state?.notification ? Object.entries(peopleData).map(([sensorId]) => sensorId) : Object.entries(peopleData).map(([sensorId]) => sensorId));
    }
  }, [state?.notification]);
  
  const handleChartClickOpen = () => {
    setopenChart(true);
  };

  const handleChartClickClose = () => {
    setopenChart(false);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  
  const fetchLastRecord = async () => {
    try {
      const response = await fetch('/api/people/lastrecord');
      const data = await response.json();
      if (data.error){
        window.location.href = '/';
        return;
      }
      Object.entries(data).forEach(([banchId, peopleData]) => {        
        const currentTime = new Date(peopleData.timestamp).getTime();
        if(peopleData.name){
          setPeopleData(prevData => ({
            ...prevData,
            [banchId]: {name:peopleData.name,...peopleData } // 更新 SPS 数据
        }));
        setLastUpdated(prevData => ({
            ...prevData,
            [banchId]: currentTime
          }));
        }
      });
    } catch (error) {
      console.error('Error fetching last record:', error);
    }
  };

  const handleEditOffset = async (id, offset) => {
    const response = await fetch('/api/people/edit_offset', {
      method: 'POST',
      body: JSON.stringify({ id, offset })
    });
    const data = await response.json();
    
    fetchLastRecord();
  };

  useEffect(() => {
    const fetchHistory = async () => {
      try {

        const currentTime = selectedDate ? new Date(selectedDate).setHours(23,59,59,0) : new Date(peopleData[selectedSensorId].timestamp).setHours(23,59,59,0);
        const starttTime = selectedDate ? new Date(selectedDate).setHours(0,0,0,0) : new Date(peopleData[selectedSensorId].timestamp).setHours(0,0,0,0);
        
        const url = "/api/people/history?deviceId=" + selectedSensorId + "&start_time=" + starttTime + "&end_time=" + currentTime;
        const response = await fetch(url);
        const data = await response.json();
        console.log(data);
        if (data.people){
          setPeopleHistory(data.people);
        }
      } catch (error) {
        console.error('Error fetching last record:', error);
      }
    };
    fetchHistory();
  }, [selectedSensorId, selectedDate]);

  useEffect(() => {
    fetchLastRecord();
    const interval = setInterval(fetchLastRecord, 60000); // 每30秒调用一次 fetchLastRecord
    return () => clearInterval(interval); // 清除定时器
  }, []);

 const filteredRecords = displayedRecords ? (Object.entries(peopleData).filter(([sensorId,data])=>{return data.name.includes(searchTerm)})):Object.entries(peopleData).filter(([sensorId]) => Object.entries(lastUpdated).map(([sensorId,data]) => sensorId).includes(sensorId)).filter(([sensorId,data])=>{return data.name.includes(searchTerm)});
  return (
    <>
    {openChart && <HistoryChart2 sensorName={peopleData[selectedSensorId].name} sensorId={selectedSensorId} open={openChart} onClose={handleChartClickClose}/>}
      <CssBaseline />
      <Container ref={containerRef}>
          <Box paddingLeft={window.innerWidth > 500 ? 20 : 0} paddingRight={window.innerWidth > 500 ? 20 : 0}>
            <div style={{marginTop:'10px'}}/>
            <Paper elevation={3}>
              <List style={{marginTop:'0px'}}>
              <ListItemButton disabled={open} onClick={() => setOpen(!open)}>
                <ListItemText primary="Search List" />
                {(selectedSensorId) && (open ? null : <ExpandMore />)}
              </ListItemButton>
              <Collapse in={open}>
                <ListItem>
                <TextField
                    style={{marginLeft:'10px',marginRight:'10px',marginTop:'10px'}}
                    variant="outlined"
                    placeholder="Search by Name"
                    value={searchTerm}
                    onChange={(e) => (
                        setButtonState(null),
                        setSearchTerm(e.target.value)
                    )}
                    fullWidth // 修改为 fullWidth
                    InputProps={{
                      endAdornment: (
                        searchTerm && (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setSearchTerm('')}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                        )
                      ),
                    }} // 添加清空按钮
                  />
                  </ListItem>
              
              <ListItem>              
              <FixedSizeList
                height={375} // 设置列表高度
                itemSize={75} // 每个项的高度
                itemCount={filteredRecords.length} // 项的数量
                width="100%" // 列表宽度
              >
                {({ index, style }) => {
                  const sensorId = filteredRecords[index][0]; // 获取传感器ID
                  return (
                    <Row
                      style={style} 
                      onClick={() => {
                        setSelectedSensorId(sensorId);
                        handleDrawerOpen(); // 点击时打开 Drawer
                        setOpen(false);
                      }} 
                      className={selectedSensorId === sensorId ? 'selected' : ''} // 添加选中样式
                      data = {filteredRecords[index]}
                      lastUpdated={lastUpdated[sensorId]} // 传递 lastUpdated 参数
                    />
                  );
                }}
              </FixedSizeList>
            </ListItem>
            </Collapse>
            </List> 
            </Paper>
            <div style={{marginTop:'10px'}}/>
            <Box flex={6}>
              {!open && selectedSensorId && (
                <SensorCard
                  key={selectedSensorId} 
                  sensorId={selectedSensorId} 
                  data={peopleData[selectedSensorId]} 
                  lastUpdated={lastUpdated[selectedSensorId]} 
                  handleChartClickOpen={handleChartClickOpen}
                  peopleHistory={peopleHistory}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  handleEditOffset={handleEditOffset}
                  displayMode={displayMode}
                  setDisplayMode={setDisplayMode}
                />
              )}
            </Box>
          </Box>
        </Container>
    </>
  );
};

export default People;